import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Masonry from "../../components/masonry"
import { graphql } from "gatsby"

export default ({ data }) => (
  <Layout>
    <SEO title="Portraits" />

    <section className="hero is-medium is-white">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-spaced is-size-3">Portraits</h1>
          <h3 className="is-size-5">
            We create portraits with a difference showing the personality and
            emotion behind the face. We believe in getting to know you so that
            we can work on getting the right look for your business portrait,
            couples, graduation or family portrait. Our service extends to
            providing the prints, canvas and framing enabling you to walk away
            with ready to hang wall art.
          </h3>
        </div>
      </div>
    </section>
    <section>
      <Masonry
        items={data.portraits.edges.map(({ node }) => ({
          image: { ...node.childImageSharp.fluid },
          publicURL: node.publicURL,
          name: node.name,
        }))}
        itemsPerRow={[1, 5]}
      />
    </section>
  </Layout>
)

export const data = graphql`
  query {
    portraits: allFile(
      filter: { relativeDirectory: { eq: "gallery/portraits" } }
    ) {
      edges {
        node {
          id
          publicURL
          name
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
